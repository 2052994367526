// @flow
import React from 'react';
import type { FrontMatter, ImageType } from '../../utils/types';
import { Image, Layout, Link, withFrontMatter } from '../../components';
import { PLATFORM_URL } from '../../utils/globals';
import { graphql } from 'gatsby';

type Props = { frontMatter: FrontMatter, data: { screenshot: ImageType } };

class Blog_2020_09_07_Super_Amazing_Ropewiki_Map extends React.PureComponent<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <Image image={data.screenshot} />
        <p>
          <Link
            to={`${PLATFORM_URL}/canyons/super-amazing-ropewiki-map.kml`}
            target="_self"
            hideExternalLink
          >
            Get the map
          </Link>{' '}
          (takes a second to load)
        </p>
        <p>
          I really like the{' '}
          <Link to="https://www.smilingcricket.com/2017/07/the-super-amazing-canyoneering-map.html">
            Super Amazing Canyoneering Map
          </Link>{' '}
          by{' '}
          <Link to="https://www.blogger.com/profile/16978567067702844987">
            Bob Allen
          </Link>
          . I really like the open nature of{' '}
          <Link to="http://ropewiki.com/Main_Page">Ropewiki</Link> and the fact
          that anyone can add a canyon so it gets all sorts of canyons. I{' '}
          <em>don't</em> like that the Super Amazing Canyoneering Map is
          maintained and updated by one person. I <em>don't</em> like that
          Ropewiki only displays 100 entries on any region page.
        </p>
        <p>This is my attempt at merging the two.</p>
        <h2>Features</h2>
        <ul>
          <li>Only canyons in the United States</li>
          <li>Updated automatically each day</li>
          <li>Organized by region</li>
        </ul>
        <h2>Weirdness</h2>
        <ul>
          <li>Has a lot of garbage (because anyone can add anything)</li>
          <li>
            Has so many canyons it can bring any computer to its knees. I hide
            most of them by default
          </li>
          <li>
            Jenky on mobile because you have to tap to show every canyon
            individually. I'll it eventually.
          </li>
        </ul>
        <h2>How to add a canyon</h2>
        <ol>
          <li>
            Go to <Link to="http://ropewiki.com/Main_Page">Ropewiki</Link>
          </li>
          <li>Log in or create an account</li>
          <li>
            Follow the steps to{' '}
            <Link to="http://ropewiki.com/Contributing#Creating_new_pages">
              add a canyon
            </Link>
          </li>
          <li>It will show up on this map tomorrow</li>
          <li>
            If you ask (
            <Link to="https://www.facebook.com/jared.robertson.142">
              me on facebook
            </Link>
            ) and I'm at my computer I can update the map immediately
          </li>
        </ol>
        <h2>General Disclaimer from Ropewiki</h2>
        <p>
          In all habitats live animals and plants that deserve respect, please
          minimize impact on the environment and observe the local ethics.
          Canyoneering, Canyoning, Caving and other activities described in this
          site are inherently dangerous. Reliance on the information contained
          on this site is solely at your own risk. There is no warranty as to
          accuracy, timeliness or completeness of the information provided on
          this site. The site administrators and all the contributing authors
          expressly disclaim any and all liability for any loss or injury
          caused, in whole or in part, by its actions, omissions, or negligence
          in procuring, compiling or providing information through this site,
          including without limitation, liability with respect to any use of the
          information contained herein. If you notice any omission or mistakes,
          please contribute your knowledge (more information).
        </p>
        <p>
          <Link to="http://ropewiki.com/Ropewiki:General_disclaimer">
            General Disclaimer on Ropewiki
          </Link>
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2020-09-07-super-amazing-ropewiki-map')(
  Blog_2020_09_07_Super_Amazing_Ropewiki_Map
);

export const query = graphql`
  query {
    screenshot: file(
      relativePath: {
        eq: "images/opengraph/2020-09-07-super-amazing-ropewiki-map.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
